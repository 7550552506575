import { Avatar, Button, message, Space } from "antd";
import React from "react";
import useFilterContact from "@/services/contact/useFilterContact";
import { getInitials } from "@/components/shared/InitialAvatar";
import { Colors } from "@/utils/app/colors";
import { LinkOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslations } from "next-intl";
import { UnmountClosed } from "react-collapse";
import CreateContactButton from "@/app/[locale]/(app)/dashboard/[COMPANY_ID]/contacts/contact/components/CreateContactButton";

interface NumberInfoProps {
  phoneNumber: string;
  size?: "small" | "large";
}

const NumberInfo = ({ phoneNumber, size = "large" }: NumberInfoProps) => {
  const t = useTranslations("call");

  const { data: listContacts, refetch } = useFilterContact({
    page: 1,
    size: 1,
    filterGroups: undefined,
    search: phoneNumber,
    orderColumn: null,
    properties: [],
  });

  const contact = listContacts?.data?.results?.[0];
  const foundContact = !!contact;
  const customerName = contact?.properties?.find(
    (e) => e.slug === "customer_name"
  )?.value;

  return (
    <div className="pt-3 px-3 w-100 d-flex flex-row justify-content-between">
      <div className={size !== "small" ? "col-7" : "col-12"}>
        <div className="mb-1">
          <Avatar
            size="small"
            style={Colors.avatarCircle}
            icon={customerName ? "" : <UserOutlined />}>
            {getInitials(customerName?.toString() || "K")}
          </Avatar>
          <span className="ms-2">
            {foundContact ? customerName?.toString() : "Không xác định"}
          </span>
        </div>
        <h5
          onClick={() => {
            navigator.clipboard.writeText(phoneNumber);
            message.success(t("copyPhoneNumberSuccess"));
          }}>
          {phoneNumber}
        </h5>
      </div>
      {size !== "small" && (
        <div className="col-5 d-flex flex-column gap-2 align-items-end">
          <UnmountClosed isOpened={!foundContact}>
            <CreateContactButton
              ui="phone_window"
              defaultValues={{
                phone_number: phoneNumber,
              }}
              callback={() => {
                refetch();
              }}
            />

            <Button
              size="small"
              block
              icon={<LinkOutlined />}
              type="primary"
              ghost>
              {t("link_contact")}
            </Button>
          </UnmountClosed>
          <UnmountClosed isOpened={foundContact}>
            <Button size="small" icon={<UserOutlined />} type="primary" ghost>
              {t("detail")}
            </Button>
          </UnmountClosed>
        </div>
      )}
    </div>
  );
};

export default NumberInfo;
